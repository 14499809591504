import type { AxiosResponse } from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { clearLocalOperations, clearLocalOrders } from '@/entities/Orders';
import type { OrdersOperList } from '@/entities/Orders/interfaces/Orders';
import { useStorage } from '@/shared/hooks';
import type { CreateConfirmInput, ErrorResponse } from '@/shared/interfaces';
import { STORAGE_ORDER_USERS, TIME_OFFSET } from '@/shared/lib';
import { formatDateToUTC } from '@/shared/lib/dateUtils';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { fetchCreateConfirm } from '../api/fetchCreateConfirm';

export const useCreateConfirmStore = defineStore('createConfirm', () => {
  const confirm = ref<ErrorResponse | undefined>(undefined);
  const { handleLoading } = useLoadingStore();
  const { handleResponse } = useAlertStore();
  const { getItem } = useStorage();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchCreateConfirmAsync = async (
    orderId: string,
    currentOperations: OrdersOperList[],
    reason?: string,
    comment?: string,
    timeStartInspection?: number,
    AFRUD?: string,
  ) => {
    const currentDateString = new Date().toString();
    const beginDateString = timeStartInspection
      ? new Date(timeStartInspection).toString()
      : currentDateString;

    const inputData = {
      IM_DATA: [],
      IM_VORNR: [],
      IM_MATERIALS: [],
    } as CreateConfirmInput;

    const people = await getItem(`${STORAGE_ORDER_USERS}_${orderId}`);

    inputData.IM_DATA.push([
      orderId,
      formatDateToUTC(beginDateString, 'yyyy-MM-dd'),
      formatDateToUTC(beginDateString, 'HH:mm:ss', TIME_OFFSET),
      formatDateToUTC(currentDateString, 'yyyy-MM-dd'),
      formatDateToUTC(currentDateString, 'HH:mm:ss', TIME_OFFSET),
      people ? JSON.parse(people).length : 0,
    ]);

    const isReject = reason || comment;

    currentOperations.forEach((operation) => {
      inputData.IM_VORNR.push([
        operation.VORNR,
        String(reason) ?? '', //GRUND
        AFRUD ?? comment ?? '', //AFRUD
        'X', //FINAL_CONF
        operation.ARBPL ?? '',
        profile.value?.personnel_number ?? '', // PERNR Табельный номер
      ]);
    });

    const tryBlock = async () => {
      const createConfirmResponse = await fetchCreateConfirm(inputData).catch(() => ({
        //если приложение не подключено к интернету то показываем уведомление об успешной отправке
        status: window.navigator.onLine ? 0 : 200,
      }));

      // Удаляем данные из хранилища после отправки их на сервер
      const success = createConfirmResponse.status === 200;
      if (success) {
        clearLocalOrders([orderId]);
        const operationIds = currentOperations.map((operation) => operation.VORNR);
        clearLocalOperations([orderId], operationIds);
      }

      handleResponse(
        createConfirmResponse as AxiosResponse,
        isReject ? 'Задание отклонено' : 'Осмотр выполнен',
      );
    };
    await handleLoading({ tryBlock, funcName: 'fetchCreateConfirmAsync' });
  };

  return {
    confirm,
    fetchCreateConfirmAsync,
  };
});
