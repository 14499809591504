import { computed, ref } from 'vue';

type UseLoadMoreParams<T> = {
  currentIndex: number;
  maxVisibleItemsCount: number;
  items: T[];
};

export function useLoadMore<T>(params: UseLoadMoreParams<T>) {
  const currentIndex = ref(params.currentIndex);
  const startIndex = computed(() => currentIndex.value * params.maxVisibleItemsCount);
  const endIndex = computed(() => startIndex.value + params.maxVisibleItemsCount);

  const newItems = computed(() => params.items.slice(startIndex.value, endIndex.value) || []);

  const hasMore = computed(() => endIndex.value < params.items.length);

  return {
    currentIndex,
    newItems,
    hasMore,
  };
}
