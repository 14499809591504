import { storeToRefs } from 'pinia';
import { ref, watchEffect } from 'vue';

import { EquipmentStatuses } from '@/shared/interfaces';
import { DEFAULT_IM_WERKS, enterprises } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useSectorsStore } from '@/shared/model/useSectorsStore';

import type { FucLockByParentNode } from '../interfaces';
import type { TechPlaceItem } from '../interfaces/TechPlaceItem';
import { useGetEquipList } from './useGetEquipList';
import { useGetFuncLocList } from './useGetFuncLocList';

export const useGetFuncLocksWerksAndAreas = () => {
  const { funcLockFetchError, reloadFetchRequest, funcLockList } = useGetFuncLocList();
  const { profile } = storeToRefs(useUserProfileStore());
  const { equipmentList } = useGetEquipList();
  const { sectors } = storeToRefs(useSectorsStore());

  const funcLocByParentNode = ref<FucLockByParentNode>({});

  watchEffect(() => {
    const enterpriseSapCode = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;

    const specificStructure = [enterprises.EEK, enterprises.ODPP, enterprises.PAP].includes(
      enterpriseSapCode,
    );

    if (
      specificStructure
        ? funcLockList.value && sectors.value && equipmentList.value
        : funcLockList.value
    ) {
      if (specificStructure) {
        const ensureArrayExists = (key: string) => {
          if (!funcLocByParentNode.value[key]) {
            funcLocByParentNode.value[key] = [];
          }
        };

        const addItemToFuncLoc = (werksKey: string, item: TechPlaceItem, key: number) => {
          const list = funcLocByParentNode.value[werksKey];
          const tplnrSet = new Set(list.map((el) => el.TPLNR));

          if (!tplnrSet.has(item.WORK_CODE!)) {
            list.push({
              TPLNR: item.WORK_CODE!,
              WERKS: item.WERKS!,
              PLTXT: item.WORK_NAME!,
              TPLMA: item.WERKS!,
              hhive_id: key,
            });
          }
        };

        const addWorkshopItem = (workCodeKey: string, item: TechPlaceItem, key: number) => {
          const list = funcLocByParentNode.value[workCodeKey];
          const tplnrSet = new Set(list.map((el) => el.TPLNR));

          if (!tplnrSet.has(item.TPLNR)) {
            list.push({
              TPLNR: item.TPLNR!,
              WERKS: item.WERKS!,
              PLTXT: item.PLTXT!,
              TPLMA: item.WORK_CODE!,
              hhive_id: key + 10,
            });
          }
        };

        sectors.value?.EX_TECHPLACE.forEach((item, key) => {
          if (!item.WERKS || !item.WORK_CODE) return;

          ensureArrayExists(item.WERKS);
          ensureArrayExists(item.WORK_CODE);
          addItemToFuncLoc(item.WERKS, item, key);
          addWorkshopItem(item.WORK_CODE, item, key);
        });

        const isValidFormat = (value: string) => /^\d{4}-\d{3}-\d{3}$/.test(value);

        const funcLocKeys = Object.keys(funcLocByParentNode.value);
        const existingTplmaSet = new Set(funcLockList.value?.EX_FUNC_LOC.map((tm) => tm.TPLMA));

        funcLockList.value?.EX_FUNC_LOC.forEach((technicalPlaces) => {
          funcLocKeys.forEach((key) => {
            const list = funcLocByParentNode.value[key];

            list.forEach((value) => {
              const isMatch =
                value.TPLNR === technicalPlaces.TPLNR ||
                value.TPLMA === technicalPlaces.TPLNR ||
                value.WERKS === technicalPlaces.TPLNR;

              if (!isMatch && value.TPLNR === technicalPlaces.TPLNR.slice(0, 12)) {
                ensureArrayExists(technicalPlaces.TPLMA);
                funcLocByParentNode.value[technicalPlaces.TPLMA].push(technicalPlaces);
              } else if (
                value.TPLNR === technicalPlaces.TPLNR &&
                isValidFormat(value.TPLNR) &&
                !existingTplmaSet.has(value.TPLNR)
              ) {
                ensureArrayExists(technicalPlaces.TPLNR);
                funcLocByParentNode.value[value.TPLNR].push({
                  TPLNR: value.TPLNR,
                  WERKS: value.WERKS,
                  PLTXT: value.PLTXT,
                  TPLMA: value.TPLNR,
                  hhive_id: value.hhive_id,
                });
              }
            });
          });
        });

        // в заводе ФООР 1016, есть такое понятие как группирующая ЕО которую использовали как ТМ для объединения ЕО со статусами УПЛН. У этой ЕО статус УПРС. В данной обработке мы эти ЕО ставим рядом с ТМ в нашем объекте funcLocByParentNode
        if ([enterprises.ODPP].includes(enterpriseSapCode)) {
          equipmentList.value!.EX_EQUIP_LIST.forEach((equipment) => {
            if (!equipment.HEQUI && equipment.STTXU === EquipmentStatuses.IDLE_LEVEL) {
              const list = funcLocByParentNode.value[equipment.TPLNR];
              if (list) {
                list.push({
                  TPLNR: equipment.EQUNR,
                  WERKS: equipment.WERKS,
                  PLTXT: equipment.EQKTX,
                  TPLMA: equipment.TPLNR,
                  hhive_id: equipment.hhive_id,
                  ifGroupingEquipment: equipment,
                });
              }
            }
          });
        }
      } else {
        funcLockList.value!.EX_FUNC_LOC?.forEach((item) => {
          const key = item.TPLMA;
          if (!funcLocByParentNode.value[key]) {
            funcLocByParentNode.value[key] = [];
          }
          funcLocByParentNode.value[key].push(item);
        });
      }
    }
  });
  return {
    funcLocByParentNode,
    funcLockFetchError,
    reloadFetchRequest,
  };
};
