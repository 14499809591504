import { EquipmentStatuses } from '../interfaces';

export function isEquipmentForDefect(STTXU?: string): boolean {
  if (!STTXU) return false;

  const statusSet = new Set(STTXU.split(' '));
  return (
    statusSet.has(EquipmentStatuses.MTORO_LEVEL) && statusSet.has(EquipmentStatuses.DEFECTS_LEVEL)
  );
}

export function isEquipmentForOrder(STTXU?: string): boolean {
  if (!STTXU) return false;

  const statusSet = new Set(STTXU.split(' '));
  return (
    statusSet.has(EquipmentStatuses.COST_LEVEL) &&
    statusSet.has(EquipmentStatuses.MTORO_LEVEL) &&
    statusSet.has(EquipmentStatuses.DEFECTS_LEVEL)
  );
}
