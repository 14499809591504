<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useGetSectors } from '@/entities/Sectors/hooks/useGetSectors';
import { SearchIcon } from '@/shared/assets/svg';
import { NAV_DASHBOARD, NAV_DEFECTS } from '@/shared/lib';
import { BottomButtons, HandleRequest, LazyLoadSelect, PageHeading, Search } from '@/shared/ui';

import styles from './Sectors.module.scss';

const router = useRouter();

const { sectors, sectorsFetchError, reloadFetchRequest } = useGetSectors();

const selectedSector = ref<string | undefined>(undefined);
const openSelect = ref<boolean>(false);
const submitDisabled = computed(() => selectedSector.value === undefined);

const search = ref(false);
const onSearchOpen = () => {
  search.value = !search.value;
};

const onSubmit = () => {
  router.push({ name: NAV_DEFECTS, params: { sectorId: selectedSector.value } });
};
const handleModalSelect = (value: boolean) => (openSelect.value = value);

const handleSelect = (value: string) => {
  selectedSector.value = value;
  handleModalSelect(false);
};

const handleBack = () => {
  if (search.value) {
    search.value = false;
  } else {
    router.push(NAV_DASHBOARD);
  }
};

const dataAvailable = computed(() => sectors.value?.EX_TECHPLACE.length);

const sectorOptions = computed(() =>
  sectors.value?.EX_TECHPLACE.map((sector) => ({ id: sector.TPLNR, name: sector.PLTXT })),
);

const titlePage = computed(() => (search.value ? 'Поиск оборудования' : 'Дефекты'));

const items = computed(
  () =>
    sectorOptions.value?.map((item) => ({ key: item.id, label: item.name, value: item.id })) || [],
);
</script>

<template>
  <div :class="styles.defect">
    <PageHeading :text="titlePage" :go-back="true" :on-handle-back="handleBack">
      <button v-if="dataAvailable" type="button" :class="styles.search" @click="onSearchOpen">
        <SearchIcon />
      </button>
    </PageHeading>

    <HandleRequest
      :no-data="!sectors?.EX_TECHPLACE.length"
      :error-request="sectorsFetchError"
      :on-repeat="reloadFetchRequest"
    />
    <template v-if="dataAvailable">
      <Search
        v-if="search && sectorOptions"
        placeholder="Введите название участка"
        :url-name="NAV_DEFECTS"
        :items="sectorOptions"
        :param-name="'sectorId'"
        :show-search-result="true"
      />
      <div :class="styles.body" v-else>
        <div :class="styles.wrap">
          <LazyLoadSelect title="Выберите учаcток" :items="items" :on-select="handleSelect" />
        </div>
        <BottomButtons>
          <a-button type="primary" :disabled="submitDisabled" size="large" @click="onSubmit"
            >Найти</a-button
          ></BottomButtons
        >
      </div>
    </template>
  </div>
</template>
