<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { nextTick, type Ref, ref, type UnwrapRef } from 'vue';
import { computed, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useLoadMore } from '@/shared/hooks/useLoadMore';
import {
  DEFAULT_IM_WERKS,
  NAV_EQUIPMENT_LINES,
  NAV_EQUIPMENT_LINES_CREATE_DEFECT,
  NAV_EQUIPMENT_SEARCH_NO_NFC,
} from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { BottomButtons, LazyLoadSelect } from '@/shared/ui';

import type { FucLockByParentNode } from '../../interfaces';
import styles from './EquipmentSearchFilter.module.scss';

interface Props {
  funcLocByParentNode: FucLockByParentNode;
}

const { funcLocByParentNode } = defineProps<Props>();
const router = useRouter();
const route = useRoute();
const userProfileStore = useUserProfileStore();
const { profile } = storeToRefs(userProfileStore);

const areasSectionRefElement = ref<HTMLElement | null>(null);

const userWerk = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;

interface FormState {
  workshop: string | undefined;
  area: number | undefined;
}

const formState: UnwrapRef<FormState> = reactive({
  workshop: undefined,
  area: undefined,
});

const submitDisabled = computed(
  () => formState.workshop === undefined || formState.area === undefined,
);

const onFinish = () => {
  router.push({
    name:
      route.path === NAV_EQUIPMENT_SEARCH_NO_NFC
        ? NAV_EQUIPMENT_LINES_CREATE_DEFECT
        : NAV_EQUIPMENT_LINES,
    params: { workshopId: formState.workshop, areaId: formState.area },
  });
};

watch(
  () => formState.workshop,
  () => {
    formState.area = undefined;
  },
);

const workshops = computed(
  () =>
    funcLocByParentNode[userWerk]?.map((workshop) => ({
      key: workshop.hhive_id.toString(),
      label: workshop.PLTXT,
      value: workshop.TPLNR,
    })) || [],
);

function scrollTo(view: Ref<HTMLElement | null>) {
  nextTick(() => {
    view.value?.scrollIntoView({ behavior: 'smooth' });
  });
}

function handleWorkshopSelect(value: string) {
  formState.workshop = value;

  scrollTo(areasSectionRefElement);
}
</script>

<template>
  <a-form
    name="equipmentSearch"
    :model="formState"
    layout="vertical"
    @finish="onFinish"
    :required-mark="false"
    :class="styles.form"
  >
    <div :class="styles.wrap">
      <LazyLoadSelect title="Цех" :items="workshops" :on-select="handleWorkshopSelect" />
      <div ref="areasSectionRefElement">
        <a-form-item
          v-if="formState.workshop"
          :name="['area']"
          label="Участок"
          :class="styles.item"
        >
          <a-radio-group
            v-model:value="formState.area"
            button-style="solid"
            :class="styles.radioGroup"
          >
            <!-- Участками являются дочерние ноды для цеха выбранного пользователем -->
            <a-radio-button
              v-for="area in funcLocByParentNode[formState.workshop] || []"
              :key="area.hhive_id"
              :value="area.TPLNR"
              ><span :class="styles.buttonLabel">{{ area.PLTXT }}</span></a-radio-button
            >
          </a-radio-group>
        </a-form-item>
      </div>
    </div>
    <BottomButtons>
      <a-button type="primary" html-type="submit" :disabled="submitDisabled" size="large"
        >Далее</a-button
      ></BottomButtons
    >
  </a-form>
</template>
