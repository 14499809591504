import type { AxiosResponse } from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { getAlternativeSectors } from '@/shared/api';
import { getSectors } from '@/shared/api/getSectors';
import type { AlternativeSector } from '@/shared/interfaces';
import { DEFAULT_IM_WERKS, enterprises } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import type { Sector, SectorsResponse } from '../interfaces';

export const useSectorsStore = defineStore('sectors', () => {
  const sectors = ref<SectorsResponse | undefined>(undefined);
  const sectorsFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchSectorsAsync = async (silent = false) => {
    const tryBlock = async () => {
      sectorsFetchError.value = false;

      const enterpriseSapCode = profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS;

      const isAlternativeSectors = [enterprises.EEK, enterprises.ODPP, enterprises.PAP].includes(
        enterpriseSapCode,
      );

      // У заводов ЕЭК, ФООР, ПАЗ другая структура ТМ, поэтому для них идет запрос YFM_MPAI099_GET_SECTORS заполненный SAP кнсультантами
      const response: AxiosResponse = isAlternativeSectors
        ? await getAlternativeSectors(enterpriseSapCode)
        : await getSectors(enterpriseSapCode);

      if (handleResponse(response)) {
        if (isAlternativeSectors) {
          const techplace: Sector[] = response.data?.EX_TECHPLACE.map(
            (item: AlternativeSector) => ({
              PLTXT: item.PLOT_NAME,
              TPLNR: item.PLOT_CODE,
              WERKS: item.WERKS,
              WERKS_NAME: item.WERKS_NAME,
              WORK_CODE: item.WORK_CODE,
              WORK_NAME: item.WORK_NAME,
            }),
          );

          const formattedSectors: SectorsResponse = {
            EX_TECHPLACE: techplace,
          };

          sectors.value = formattedSectors;
        } else {
          sectors.value = response.data as SectorsResponse;
        }
      } else {
        sectorsFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchSectorsAsync', noGlobalLoader: silent });
  };

  return {
    sectors,
    sectorsFetchError,
    fetchSectorsAsync,
  };
});
