// eslint-disable-next-line import/no-extraneous-dependencies
import { browserTracingIntegration, captureConsoleIntegration, init } from '@sentry/vue';
import type { App } from 'vue';

import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_TRACES_SAMPLE_RATE } from './config';

export function initSentry(app: App) {
  if (import.meta.env.DEV) return;

  init({
    app,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    attachStacktrace: true,
    integrations: [
      browserTracingIntegration({ traceFetch: true, traceXHR: true }),
      captureConsoleIntegration({ levels: ['error', 'warn', 'log'] }),
    ],
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  });
}
