import { getMe } from '@orta/bridge';
import { addHours, format, subHours } from 'date-fns';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { isInsideMobileApp } from '@/app/bridge';
import { useStorage } from '@/shared/hooks/useStorage';
import { type Profile, Roles } from '@/shared/interfaces';
import { STORAGE_DATA_SYNC, STORAGE_MOCK_USER_ID } from '@/shared/lib';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';

import { fetchUserProfile } from '../api/fetchUserProfile';
import { parseJwt } from '../lib/parseJwt';

export const useUserProfileStore = defineStore('userProfile', () => {
  const profile = ref({
    role_id: Roles.TECHNOLOGIST,
  } as Profile);
  const { handleLoading } = useLoadingStore();
  const { handleResponse } = useAlertStore();

  const fetchUserProfileAsync = async (keycloakToken: string, initial = false) => {
    const tryBlock = async () => {
      const { getItem } = useStorage();
      // TODO: для тестирования на вебе
      const getMeResponse = isInsideMobileApp ? await getMe() : undefined; //Получение данных о юзере из приложения орта;
      const response = await fetchUserProfile(
        window.localStorage.getItem(STORAGE_MOCK_USER_ID) ||
          getMeResponse?.iin ||
          import.meta.env.VITE_MOCK_USER_ID,
      );
      // Если пришло ошибка из оперплана, то просто возвращаем техник
      if (response.data.status_code || response.data.detail) {
        handleResponse({
          ...response,
          status: 500,
          data: { ...response.data, description: 'Произошла ошибка при получении профиля' },
        });
        profile.value = { ...response.data, role_id: Roles.TECHNOLOGIST } as Profile;
        return;
      }

      let startDatetimeWithOffset = null;
      let endDatetimeWithOffset = null;

      if (
        response.data?.shift_start_date &&
        response.data?.start_time &&
        response.data?.shift_end_date &&
        response.data?.end_time
      ) {
        const startDatetime = `${response.data?.shift_start_date}T${response.data?.start_time}`;
        startDatetimeWithOffset = subHours(new Date(startDatetime), 0.5);

        const endDatetime = `${response.data?.shift_end_date}T${response.data?.end_time}`;
        endDatetimeWithOffset = addHours(new Date(endDatetime), 2);
      }

      const setStylesAndGetRole = (roleId: Roles) => {
        const roleClassMap = {
          [Roles.TECHNOLOGIST]: Roles.TECHNOLOGIST,
          [Roles.REPAIRER]: Roles.REPAIRER,
          [Roles.EMPLOYEE]: Roles.TECHNOLOGIST,
          [Roles.REPAIR_SUPERVISOR]: Roles.REPAIR_SUPERVISOR,
          [Roles.TECHNOLOG_SUPERVISOR]: Roles.TECHNOLOG_SUPERVISOR,
        };

        const roleClass = roleClassMap[roleId];
        if (roleClass) {
          document.body.classList.add(roleClass);
          return roleClass;
        }
      };

      const getRoleByKeycloak = () => {
        const jwt = parseJwt(keycloakToken);
        if (jwt.resource_access?.mob_toro) {
          const { roles } = jwt.resource_access.mob_toro;
          const filteredRoles = roles.map((role: string) => role.replace('ROLE_', ''));

          // Todo: Пока берём первую роль из массива и определяем её как текущая
          const currentRole = filteredRoles[0];

          if (currentRole) {
            const findRoleKey = Object.keys(Roles)[
              Object.values(Roles).indexOf(currentRole as Roles)
            ] as keyof typeof Roles;
            return Roles[findRoleKey] || Roles.EMPLOYEE;
          } else {
            return Roles.EMPLOYEE; // Если получаем пустую роль, то считаем, что пользователь сотрудник актива
          }
        }
        return Roles.EMPLOYEE;
      };

      profile.value = {
        ...response.data,
        startDatetimeWithOffset: startDatetimeWithOffset
          ? format(startDatetimeWithOffset, "yyyy-MM-dd'T'HH:mm")
          : null,
        endDatetimeWithOffset: endDatetimeWithOffset
          ? format(endDatetimeWithOffset, "yyyy-MM-dd'T'HH:mm")
          : null,
        role_id: !response.data.role_id
          ? setStylesAndGetRole(getRoleByKeycloak())
          : setStylesAndGetRole(response.data.role_id),
        last_sync_date: (await getItem(STORAGE_DATA_SYNC)) ?? '',
      };
    };

    await handleLoading({
      tryBlock,
      funcName: 'fetchUserProfileAsync',
      initialData: initial,
    });
  };

  const updateLastSyncDate = (date: string) => {
    profile.value.last_sync_date = date;
  };

  return {
    profile,
    userIsTechnologist: computed(() => profile.value.role_id === Roles.TECHNOLOGIST),
    userIsRepairer: computed(() => profile.value.role_id === Roles.REPAIRER),
    userIsSupervisor: computed(
      () =>
        profile.value.role_id === Roles.REPAIR_SUPERVISOR ||
        profile.value.role_id === Roles.TECHNOLOG_SUPERVISOR,
    ),
    userIsRepairSupervisor: computed(() => profile.value.role_id === Roles.REPAIR_SUPERVISOR),
    userIsTechnologSupervisor: computed(() => profile.value.role_id === Roles.TECHNOLOG_SUPERVISOR),
    userIsEmployee: computed(() => profile.value.role_id === Roles.EMPLOYEE),
    fetchUserProfileAsync,
    updateLastSyncDate,
  };
});
