<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { UnwrapRef } from 'vue';
import { computed, h, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  defectPriorities,
  getSymptomsByCatalogCode,
  type Symptom,
  useDefectsListStore,
} from '@/entities/Defects';
import type { Equipment } from '@/entities/Equipments/interfaces';
import type { PriorityListData } from '@/entities/Priorities';
import { PlusIcon } from '@/shared/assets/svg';
import { type AntUploadItem, EquipmentStatuses } from '@/shared/interfaces';
import {
  NAV_DASHBOARD,
  NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
  NAV_DEFECT_DETAIL,
} from '@/shared/lib';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { BottomButtons, InformationDialog, SelectCustomize } from '@/shared/ui';

import { useDefectManagementStore } from '../..';
import type {
  CreateDefectInput,
  FormStateDefectManagement,
} from '../../interfaces/DefectManagement';
import { ACCEPT_FILE_EXTENSIONS, formatFile, validateFile } from '../../lib';
import styles from './DefectCreateForm.module.scss';

interface Props {
  nodes: Equipment[];
  priorityList: PriorityListData[];
  symptoms: Symptom[];
  onUnits?: (unit: string) => void;
}

const { nodes, priorityList, symptoms, onUnits } = defineProps<Props>();
const route = useRoute();
const router = useRouter();
const { orderId, operationId } = route.params;

const isCreateFormDetail = route.path.includes(NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL);

const createDefectStore = useDefectManagementStore();
const { createDefectValues, defectId, successCreate } = storeToRefs(createDefectStore);

const defectsListStore = useDefectsListStore();
const { defectsList } = storeToRefs(defectsListStore);

const { setAlert } = useAlertStore();

const defaultFormData = {
  EQUNR: '',
  QMTXT: '',
  PRIOK: priorityList?.[0].PRIOK || '',
  CODE: '',
  CLOSE_NOTIF: false,
  FILES: [],
};

const formState: UnwrapRef<FormStateDefectManagement> = reactive({
  ...defaultFormData,
  ...createDefectValues.value,
});

const formSubmitting = ref(false);
const formSent = ref(false);

const currentNodeSymptoms = computed(() => {
  const selectedNode = nodes.find((node) => node.EQUNR === formState.EQUNR);
  return getSymptomsByCatalogCode(selectedNode?.RBNR || '', symptoms) || [];
});

const selectedPriority = computed(() => {
  // Текст описания дефекта и цвета хардкод из defectPriorities
  return defectPriorities.find((p) => p.id === formState.PRIOK) || undefined;
});

const errorModalText = ref('');
const handleCloseErrorModal = () => {
  errorModalText.value = '';
};

const formFiles = ref<AntUploadItem[]>(createDefectValues.value.FILES || []);
const handleFileInputChange = async ({
  file,
  fileList,
}: {
  file: AntUploadItem;
  fileList: AntUploadItem[];
}) => {
  // при изменении инпута получаем текущий файл и весь лист ранее приложенных
  if (file.status === 'removed') {
    formFiles.value = formFiles.value.filter(
      (f: AntUploadItem) => f.originFileObj.name !== file.originFileObj.name,
    );
    return;
  }
  if (fileList) {
    for (const file of fileList) {
      const result = await validateFile(file);
      if (
        result.ok &&
        formFiles.value.findIndex(
          (f: AntUploadItem) => f.originFileObj.name === file.originFileObj.name,
        ) === -1
      ) {
        formFiles.value.push(file);
      } else if (!result.ok) {
        errorModalText.value = result.error;
        return false;
      }
    }

    createDefectValues.value.FILES = formFiles.value;
  }
};

const onFinish = async (formData: FormStateDefectManagement) => {
  formSubmitting.value = true;
  let files: CreateDefectInput['files'] = [];
  if (formFiles.value.length > 0) {
    const formattedDocuments = await formatFile(formFiles.value.map((file) => file.originFileObj));
    if (formattedDocuments) {
      files = formattedDocuments;
    }
  }

  const selectedSymptom = symptoms.find((s) => s.CODE === formData.CODE);
  await createDefectStore.createDefectAsync({
    formData,
    TPLNR: nodes.find((node) => node.EQUNR === formState.EQUNR)?.TPLNR || '',
    orderId: orderId as string,
    operationId: operationId as string,
    files,
    symptom: {
      OTGRP: selectedSymptom?.CODEGRUPPE || '',
      OTEIL: selectedSymptom?.CODE || '',
    },
    ...(!isCreateFormDetail && { successMessage: 'Дефект создан' }),
  });
  formSent.value = true;
  createDefectValues.value = { ...defaultFormData };

  if (isCreateFormDetail && defectId.value) {
    await defectsListStore.fetchDefectsListAsync({
      IM_WERKS: '',
      IM_TPLNR: '',
      IM_EQUNR: [],
      IM_EQUNR_HISTORY: [],
      IM_QMNUM: defectId.value,
    });

    const findDefect = defectsList.value?.EX_DEFECT_LIST.find(
      (defect) => defect.QMNUM === defectId.value,
    );

    if (findDefect) {
      router.replace({ name: NAV_DEFECT_DETAIL, params: { id: findDefect.QMNUM } });
    } else {
      successCreate.value && setAlert({ type: 'success', message: 'Дефект создан' });
      router.push({ name: NAV_DASHBOARD });
    }
  } else {
    orderId ? router.go(-2) : router.push({ name: NAV_DASHBOARD });
  }

  formSubmitting.value = false;
};

watch(
  () => formState.CLOSE_NOTIF,
  (newValue) => {
    //При переключении Устранен на месте выставляем первый приоритет
    if (newValue) formState.PRIOK = priorityList?.[0].PRIOK || '';
  },
);

watch(
  () => formState.EQUNR,
  (newValue) => {
    //При выборке Узла меняем номер для Истории дефектов
    if (newValue) onUnits?.(newValue);
  },
);

onMounted(() => {
  // Мы не можем выбрать для отправки ЕО у которого статус СТМТ
  defaultFormData.EQUNR =
    nodes.find((eo) => eo.STTXU !== EquipmentStatuses.MTORO_LEVEL)?.EQUNR || '';
  onUnits?.(formState.EQUNR);
  formState.EQUNR = defaultFormData.EQUNR || createDefectValues.value.EQUNR;
  createDefectValues.value = { ...defaultFormData };
  selectedUnit.value = formState.EQUNR || createDefectValues.value?.EQUNR;
});

watchEffect(() => {
  if (!formSent.value) {
    createDefectValues.value = { ...createDefectValues.value, ...formState };
  }
});

const showAllSymptoms = ref(false);
const openSelect = ref<boolean>(false);
const selectedUnit = ref<string | undefined>(formState.EQUNR);

const submitDisabled = computed(() => !formState.QMTXT || !formState.CODE || formSubmitting.value);

const handleModalSelect = (value: boolean) => (openSelect.value = value);

const handleSelect = (item: any) => {
  // Мы не можем выбрать для отправки ЕО у которого статус СТМТ
  if (item.data.STTXU !== EquipmentStatuses.MTORO_LEVEL) {
    selectedUnit.value = item.id;
    formState.EQUNR = item.id;
  } else {
    return;
  }
  handleModalSelect(false);
};
const unitOptions = computed(() =>
  nodes.map((unit) => ({ id: unit.EQUNR, name: unit.EQKTX, data: unit })),
);
const handleClose = () => handleModalSelect(false);
</script>

<template>
  <a-form
    name="defectCreate"
    :model="formState"
    layout="vertical"
    @finish="onFinish"
    :required-mark="false"
    :class="styles.form"
  >
    <div :class="styles.wrap">
      <a-form-item label="Узел" :name="['EQUNR']" item-margin-bottom="0">
        <SelectCustomize
          :items="unitOptions"
          :on-modal-select="handleModalSelect"
          :on-select="handleSelect"
          :open="openSelect"
          :selected="selectedUnit"
          :on-close="handleClose"
          :title="'Выберите узел'"
          :disabled="nodes.length <= 1"
          size="sm"
        />
      </a-form-item>
      <!-- Todo временный коммент до подключения фм симптомов -->
      <a-form-item :name="['CODE']" label="Симптом дефекта">
        <a-radio-group v-model:value="formState.CODE" button-style="solid" :class="styles.symptom">
          <a-radio-button
            v-for="symptom in currentNodeSymptoms.slice(0, 10)"
            :key="symptom.CODE"
            :value="symptom.CODE"
            >{{ symptom.CODE_TXT }}</a-radio-button
          >
          <template v-if="showAllSymptoms">
            <a-radio-button
              v-for="symptom in currentNodeSymptoms.slice(10)"
              :key="symptom.CODE"
              :value="symptom.CODE"
              >{{ symptom.CODE_TXT }}</a-radio-button
            >
          </template>
          <a-button
            v-if="currentNodeSymptoms.length > 10"
            :class="styles.more"
            @click="showAllSymptoms = !showAllSymptoms"
          >
            {{ showAllSymptoms ? 'Скрыть...' : 'Еще...' }}
          </a-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="Описание дефекта" :name="['QMTXT']">
        <a-textarea
          v-model:value="formState.QMTXT"
          :class="styles.textarea"
          :maxlength="255"
          show-count
        />
      </a-form-item>
      <a-form-item name="fileList">
        <a-upload
          :file-list="formFiles"
          name="fileList"
          @change="handleFileInputChange"
          :before-upload="() => false"
          list-type="picture"
          :class="styles.upload"
          :accept="ACCEPT_FILE_EXTENSIONS"
          :multiple="false"
        >
          <a-button
            size="large"
            type="dashed"
            :class="styles.add"
            :icon="h(PlusIcon)"
            :disabled="Boolean(formFiles?.length > 4) || false"
            >Фото/Видео</a-button
          >
        </a-upload>
      </a-form-item>
      <a-form-item :name="['PRIOK']" label="Приоритет">
        <a-radio-group
          v-model:value="formState.PRIOK"
          button-style="solid"
          :class="styles.priority"
        >
          <a-radio-button
            :disabled="formState.CLOSE_NOTIF"
            v-for="priority in priorityList"
            :key="priority.PRIOK"
            :value="priority.PRIOK"
            >{{ priority.PRIOK }}</a-radio-button
          >
        </a-radio-group>
        <a-typography-text :class="styles.name" :style="{ color: selectedPriority?.color }">{{
          selectedPriority?.name
        }}</a-typography-text>
      </a-form-item>
      <a-form-item label="Устранен на месте" :name="['CLOSE_NOTIF']" :class="styles.inPlace">
        <a-switch v-model:checked="formState.CLOSE_NOTIF" />
      </a-form-item>
    </div>

    <BottomButtons>
      <a-button type="primary" html-type="submit" size="large" :disabled="submitDisabled"
        >Добавить</a-button
      ></BottomButtons
    >
  </a-form>

  <InformationDialog
    v-if="errorModalText"
    title="Ошибка"
    :text="errorModalText"
    :on-close="() => handleCloseErrorModal()"
  />
</template>
