<template>
  <div>
    <a-typography-title :level="5" :class="styles.title">{{ props.title }}</a-typography-title>
    <a-radio-group
      v-model:value="selectedValue"
      button-style="solid"
      :class="styles.radioGroup"
      @change="handleChange"
    >
      <a-radio-button v-for="item in loadedItems" :key="item.key" :value="item.value"
        ><span :class="styles.buttonLabel">{{ item.label }}</span></a-radio-button
      >
      <a-button v-if="hasMore" shape="round" @click="handleLoadMoreWorksButtonClick">...</a-button>
    </a-radio-group>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import { useLoadMore } from '../../hooks/useLoadMore';
import styles from './LazyLoadSelect.module.scss';

const CURRENT_INDEX = 0;
const MAX_VISIBLE_ITEMS_COUNT = 5;
const INDEX_INCREMENT = 1;

const props = defineProps<{
  title: string;
  items: Array<{
    key: string;
    label: string;
    value: string;
  }>;
  onSelect: (value: string) => void;
}>();

const selectedValue = ref('');

const { currentIndex, newItems, hasMore } = useLoadMore({
  currentIndex: CURRENT_INDEX,
  maxVisibleItemsCount: MAX_VISIBLE_ITEMS_COUNT,
  items: props.items || [],
});

const loadedItems = ref([...newItems.value]);

function handleLoadMoreWorksButtonClick() {
  currentIndex.value += INDEX_INCREMENT;

  loadedItems.value = [...loadedItems.value, ...newItems.value];
}

function handleChange(event: Event) {
  props.onSelect((event.target as HTMLInputElement).value);
}
</script>
